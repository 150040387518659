import { FormEvent, useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { AccountMigrationLoginResponse, GetAccountResult } from '@frontastic-engbers/types/account/Account';
import { Block, Button } from '@engbers/components';
import { InputText } from '@engbers/components/shopmacher-ui/input-text';
import { useModalActions } from '@frontastic-engbers/lib/state/modal/actions';
import { MigrationStepTwoModal } from './step-two-modal';
import { useAccount } from '@frontastic-engbers/lib';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
type MigrationLoginFirstStepFormData = {
  email: string;
};
type MigrationLoginFormProps = {
  onMigrationLoginFirstStepCallback?: (formData: MigrationLoginFirstStepFormData, onResponse: (response: GetAccountResult) => void) => Promise<void>;
  onMigrationLoginSecondStepCallback?: (response: AccountMigrationLoginResponse) => Promise<void>;
  migrationEmailTooltipHeadline?: string;
  migrationEmailTooltipContent?: string;
  migrationEmailPlaceholder?: string;
  migrationButtonLabel?: string;
  migrationVerifyInformation?: string;
  migrationVerifyBirthdate?: string;
  isEngbersOS?: boolean;
};
export const MigrationLoginForm: React.FC<MigrationLoginFormProps> = ({
  onMigrationLoginFirstStepCallback,
  onMigrationLoginSecondStepCallback,
  migrationEmailTooltipContent,
  migrationEmailTooltipHeadline,
  migrationEmailPlaceholder,
  migrationButtonLabel,
  migrationVerifyInformation,
  migrationVerifyBirthdate,
  isEngbersOS = false
}) => {
  const {
    login
  } = useAccount();
  const {
    pushModal
  } = useModalActions();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [emailInput, setEmailInput] = useState<string>('');
  const [isMinWidthMedium, setIsMinWidthMedium] = useState<boolean>(false);
  const isMobile = useMediaQuery({
    maxWidth: 1023
  });
  useEffect(() => {
    if (isMobile !== isMinWidthMedium) {
      setIsMinWidthMedium(isMobile);
    }
  }, [isMobile]);
  const {
    formatMessage: formatErrorMessage
  } = useFormat({
    name: 'error'
  });
  const {
    formatMessage: formatAccountMessage
  } = useFormat({
    name: 'account'
  });
  const onLoginResponse = (loginResponse: GetAccountResult) => {
    try {
      if (loginResponse.status === 'INVALID_CREDENTIALS') {
        setError(formatErrorMessage({
          id: 'auth.wrong2'
        }));
        return;
      }
      if (loginResponse.status === 'MIGRATION_LOGIN') {
        setError('');
        pushModal({
          id: 'migration-login-step-2',
          title: formatErrorMessage({
            id: 'securityQuery'
          }),
          content: <MigrationStepTwoModal loading={loading} onMigrationLoginSecondStepCallback={onMigrationLoginSecondStepCallback} cardId={loginResponse.account?.cardId} migrationVerifyInformation={migrationVerifyInformation} migrationVerifyBirthdate={migrationVerifyBirthdate} />
        });
      }
    } catch (err) {
      setError('Sorry. Something went wrong..');
      return;
    }
  };
  const onMigrationLoginFirstStep = useCallback(async (formData, onResponse) => {
    const response = await login(formData.email, '');
    onResponse(response);
  }, [login]);
  const handleFirstStepSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    if (typeof onMigrationLoginFirstStepCallback === 'function') {
      return onMigrationLoginFirstStepCallback({
        email: emailInput
      }, onLoginResponse);
    }
    await onMigrationLoginFirstStep({
      email: emailInput
    }, onLoginResponse);
    setLoading(false);
  };
  return <form onSubmit={handleFirstStepSubmit} data-sentry-component="MigrationLoginForm" data-sentry-source-file="index.tsx">
      <Block marginBottom={isEngbersOS ? 4 : 2} data-sentry-element="Block" data-sentry-source-file="index.tsx">
        <InputText id="email_Migration" name="email" errorMessage={error} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmailInput(e.target.value)} placeholder={migrationEmailPlaceholder || formatAccountMessage({
        id: 'email_or_customer_id.enter'
      })} value={emailInput} infoText={migrationEmailTooltipContent} infoTextHeadline={migrationEmailTooltipHeadline} useInfoModal data-sentry-element="InputText" data-sentry-source-file="index.tsx" />
      </Block>
      <Block customStyle={isEngbersOS ? {
      marginLeft: 'auto'
    } : undefined} maxWidth={isEngbersOS && !isMinWidthMedium ? 'fit-content' : undefined} marginBottom={2} data-sentry-element="Block" data-sentry-source-file="index.tsx">
        <Button size="large" isLoading={loading} onClick={handleFirstStepSubmit} label={migrationButtonLabel || formatAccountMessage({
        id: 'sign.activte'
      })} customStyle={isEngbersOS ? {
        paddingLeft: '30px',
        paddingRight: '30px'
      } : undefined} data-sentry-element="Button" data-sentry-source-file="index.tsx" />
      </Block>
    </form>;
};