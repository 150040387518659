import {
  Payment as CommercetoolsPayment,
  PaymentDraft,
} from '@commercetools/platform-sdk';
import { Cart } from '@frontastic-engbers/types/cart/Cart';
import { Payment } from '@frontastic-engbers/types/cart/Payment';
import { PAYONE_STATUS } from '@frontastic-engbers/helpers/constants/payone';
import { CustomerOrigin, PaymentMethod, PaymentMethods } from '@frontastic-engbers/types/engbers-custom';

export class PaymentMapper {
  public static readonly STATE_AUTHORIZED = 'payment-authorized';
  public static readonly STATE_FAILED = 'payment-failed';
  public static readonly STATE_INITIALIZED = 'payment-initialized';

  public static readonly ORDER_PAYMENT_STATE_FAILED = 'Failed';
  public static readonly ORDER_PAYMENT_STATE_PENDING = 'Pending';
  public static readonly ORDER_PAYMENT_STATE_PAID = 'Paid';

  public static readonly PAYONE_STATUS_CODES_AUTHORIZED = [PAYONE_STATUS.AUTHORIZED, PAYONE_STATUS.PAYMENT_REQUESTED];
  public static readonly PAYONE_STATUS_CODES_INITIALIZED = [
    PAYONE_STATUS.INCOMPLETE_OR_INVALID, // e.g. paypal payment before redirect!
    PAYONE_STATUS.AUTHORIZATION_WAITING,
    PAYONE_STATUS.AUTHORIZATION_NOT_KNOWN,
    PAYONE_STATUS.WAITING_AUTHENTICAION,
  ];
  public static readonly PAYONE_STATUS_CODES_REDIRECTABLE = [
    PAYONE_STATUS.ORDER_STORED,
    PAYONE_STATUS.PAYMENT_REQUESTED,
    PAYONE_STATUS.WAITING_CLIENT_PAYMENT,
    PAYONE_STATUS.AUTHORIZED,
    PAYONE_STATUS.AUTHORIZATION_WAITING,
    PAYONE_STATUS.AUTHORIZATION_NOT_KNOWN,
    PAYONE_STATUS.WAITING_AUTHENTICAION,
    PAYONE_STATUS.PAYMENT_PROCESSING,
    PAYONE_STATUS.PAYMENT_UNCERTAIN,
  ];
  public static PAYONE_STATUS_CODES_CANCELLED = [
    PAYONE_STATUS.CANCELLED_BY_CLIENT,
    PAYONE_STATUS.AUTHORIZATION_DECLINED,
    PAYONE_STATUS.AUTHORIZED_AND_CANCELLED,
    PAYONE_STATUS.PAYMENT_DECLINED_BY_ACQUIRER,
    PAYONE_STATUS.PAYMENT_REFUSED,
  ];

  public static readonly CANCEL_PAYMENT = {
    SUCCESS: 'SUCCESS',
    FAILURE_PP: 'FAILURE_PP',
    FAILURE_CC: 'FAILURE_CC',
    FAILURE_GENERAL: 'FAILURE_GENERAL',
    IGNORED: 'IGNORED',
  };

  private static readonly SECURE_PAYMENTS = ['prepayment', 'invoice'];

  public static paymentToPaymentDraft(
    payment: Payment,
    status?: number,
    label?: string,
    customerId?: string,
    brand?: string,
  ): PaymentDraft {
    const reference = payment.paymentDetails?.['merchantReference'] ?? payment.id;

    return {
      key: payment.id,
      amountPlanned: {
        centAmount: payment.amountPlanned.centAmount,
        currencyCode: payment.amountPlanned.currencyCode,
      },
      interfaceId: payment.paymentId,
      paymentMethodInfo: {
        paymentInterface: payment.paymentProvider,
        method: payment.paymentMethod,
        name: {
          'de-DE': label,
        },
      },
      paymentStatus: {
        interfaceCode: payment.paymentStatus,
        interfaceText: payment.debug,
        state: {
          typeId: 'state',
          key: PaymentMapper.isSecurePayment(payment)
            ? PaymentMapper.STATE_AUTHORIZED
            : PaymentMapper.payoneStatusToPaymentState(status),
        },
      },
      ...(customerId && {
        customer: {
          typeId: 'customer',
          id: customerId,
        },
      }),
      ...((brand || reference) && {
        custom: {
          type: {
            typeId: 'type',
            key: 'payment-additional-info-type',
          },
          fields: {
            cardBrand: brand,
            merchantReference: reference,
          },
        },
      }),
    } as PaymentDraft;
  }

  public static cartToOrderPaymentState(cart: Cart): string {
    const payments = cart.payments ?? [];
    return PaymentMapper.getOrderStateByPayments(payments);
  }

  public static payoneStatusToPaymentState(status?: number): string {
    if (status === undefined) {
      return PaymentMapper.STATE_FAILED;
    }

    return PaymentMapper.isAuthorized(status)
      ? PaymentMapper.STATE_AUTHORIZED
      : PaymentMapper.isInitialized(status)
        ? PaymentMapper.STATE_INITIALIZED
        : PaymentMapper.STATE_FAILED;
  }

  public static isRedirectableStatus(status: number): boolean {
    return PaymentMapper.PAYONE_STATUS_CODES_REDIRECTABLE.includes(status);
  }

  public static mapPaymentMethods(
    paymentMethodTypes: PaymentMethod[],
    paymentMethods: PaymentMethods,
    customerOrigin: CustomerOrigin,
  ): PaymentMethod[] {
    return paymentMethodTypes.map((method) => {
      if (method.type in paymentMethods) {
        method.isVisible = true;
        method.canUse = paymentMethods[method.type];

        if (method.type === 'prepayment') {
          method.canUse = !customerOrigin.isGuest;

          if (!customerOrigin.isGuest && !customerOrigin.isNew) {
            method.canUse = true;
          }
        }
      } else {
        method.isVisible = false;
        method.canUse = false;
      }

      return method;
    });
  }

  public static fetchLastPayment(payments: Array<Payment | CommercetoolsPayment>) {
    return PaymentMapper.getLatestPayment(payments, [
      ...PaymentMapper.PAYONE_STATUS_CODES_INITIALIZED,
      ...PaymentMapper.PAYONE_STATUS_CODES_AUTHORIZED,
    ]);
  }

  private static hasAuthorizedPayment(payments: Array<Payment | CommercetoolsPayment>): boolean {
    return payments.some((payment) => PaymentMapper.isAuthorized(PaymentMapper.getPaymentStatusCode(payment)));
  }

  private static hasInitializedPayment(payments: Array<Payment | CommercetoolsPayment>): boolean {
    return payments.some((payment) => PaymentMapper.isInitialized(PaymentMapper.getPaymentStatusCode(payment)));
  }

  private static getLatestPayment(
    payments: Array<Payment | CommercetoolsPayment>,
    validStates: number[],
  ): Payment | CommercetoolsPayment | null {
    return payments
      .filter((payment) => validStates.includes(PaymentMapper.getPaymentStatusCode(payment)))
      .reduce((latest: Payment | CommercetoolsPayment | null, current: Payment | CommercetoolsPayment) => {
        if (!latest?.createdAt) {
          return current;
        }

        if (!current.createdAt) {
          return latest;
        }

        return latest.createdAt < current.createdAt ? current : latest;
      }, null);
  }

  private static hasUnsecurePayment(payments: Array<Payment | CommercetoolsPayment>): boolean {
    return payments.some(
      (payment) =>
        !PaymentMapper.isSecurePayment(payment) &&
        !PaymentMapper.isCancelled(PaymentMapper.getPaymentStatusCode(payment)),
    );
  }

  private static isAuthorized(status: number): boolean {
    return PaymentMapper.PAYONE_STATUS_CODES_AUTHORIZED.includes(status);
  }

  private static isInitialized(status: number): boolean {
    return PaymentMapper.PAYONE_STATUS_CODES_INITIALIZED.includes(status);
  }

  private static isCancelled(status: number): boolean {
    return PaymentMapper.PAYONE_STATUS_CODES_CANCELLED.includes(status);
  }

  private static getOrderStateByPayments(payments: Array<Payment | CommercetoolsPayment>): string {
    const latestPayment = PaymentMapper.getLatestPayment(payments, [
      ...PaymentMapper.PAYONE_STATUS_CODES_INITIALIZED,
      ...PaymentMapper.PAYONE_STATUS_CODES_AUTHORIZED,
    ]);

    return PaymentMapper.hasAuthorizedPayment(payments) ||
      (latestPayment && PaymentMapper.isSecurePayment(latestPayment))
      ? PaymentMapper.ORDER_PAYMENT_STATE_PAID
      : PaymentMapper.hasInitializedPayment(payments)
        ? PaymentMapper.ORDER_PAYMENT_STATE_PENDING
        : PaymentMapper.ORDER_PAYMENT_STATE_FAILED;
  }

  private static getPaymentStatusCode(payment: Payment | CommercetoolsPayment): number {
    const paymentStatus = PaymentMapper.isCommercetoolsPayment(payment)
      ? +payment.paymentStatus.interfaceCode
      : PaymentMapper.isPayment(payment)
        ? +payment.paymentStatus
        : -1;

    return paymentStatus ?? -1;
  }

  private static isPayment(payment: any): payment is Payment {
    return (
      (payment as Payment).id !== undefined &&
      (payment as Payment).paymentProvider !== undefined &&
      (payment as Payment).paymentId !== undefined
    );
  }

  private static isSecurePayment(payment: Payment | CommercetoolsPayment): boolean {
    const paymentMethod = PaymentMapper.isCommercetoolsPayment(payment)
      ? payment.paymentMethodInfo.method
      : payment.paymentMethod;

    return paymentMethod !== undefined && PaymentMapper.SECURE_PAYMENTS.includes(paymentMethod);
  }

  private static isCommercetoolsPayment(payment: any): payment is CommercetoolsPayment {
    return (
      (payment as CommercetoolsPayment).id !== undefined &&
      (payment as CommercetoolsPayment).version !== undefined &&
      (payment as CommercetoolsPayment).createdAt !== undefined &&
      (payment as CommercetoolsPayment).paymentMethodInfo !== undefined
    );
  }
}
