import React from 'react';
import classNames from 'classnames';
import { markdown } from 'markdown';
import styles from './input-checkbox.module.scss';
export interface IInputCheckbox {
  checked?: boolean;
  disabled?: boolean;
  defaultChecked?: boolean;
  label?: string | React.ReactNode;
  name?: string;
  id?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errorMessage?: string;
  swapLabelPosition?: boolean;
  hasHoverEffect?: boolean;
  style?: 'gray' | 'white';
  labelIsMarkdown?: boolean;
}
export const InputCheckbox: React.FC<IInputCheckbox> = ({
  label = '',
  checked,
  disabled = false,
  defaultChecked,
  name,
  id,
  onChange,
  onBlur,
  errorMessage,
  swapLabelPosition,
  hasHoverEffect = false,
  style = 'gray',
  labelIsMarkdown = false
}) => {
  return <div data-sentry-component="InputCheckbox" data-sentry-source-file="index.tsx">
      <div className={styles.row}>
        <input className={classNames(styles.checkbox, {
        [styles.hasHoverEffect]: hasHoverEffect && !disabled,
        [styles.order]: swapLabelPosition,
        [styles.checkboxWhiteStyle]: style === 'white',
        [styles.checkboxDisabled]: disabled
      })} onChange={onChange} onBlur={onBlur} id={id} name={name} checked={checked} defaultChecked={defaultChecked} disabled={disabled} type="checkbox" />
        {label && <label className={styles.label} htmlFor={id} dangerouslySetInnerHTML={labelIsMarkdown ? {
        __html: markdown.toHTML(label)
      } : undefined}>
            {labelIsMarkdown ? undefined : label}
          </label>}
      </div>

      {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
    </div>;
};