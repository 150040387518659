import classNames from 'classnames';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import styles from './input-toggle.module.scss';
interface IInputToggle {
  isActive?: boolean;
  onChange?: () => void;
  activateAriaLabel?: string;
  deactivateAriaLabel?: string;
}
export const InputToggle: React.FC<IInputToggle> = ({
  isActive,
  onChange,
  activateAriaLabel,
  deactivateAriaLabel
}) => {
  const {
    formatMessage
  } = useFormat({
    name: 'common'
  });
  const label = isActive ? formatMessage({
    id: 'yes'
  }) : formatMessage({
    id: 'no'
  });
  const hasLabels = !!(activateAriaLabel && deactivateAriaLabel);
  const ariaLabel = hasLabels ? isActive ? deactivateAriaLabel : activateAriaLabel : undefined;
  return <button className={classNames(styles.wrap, {
    [styles['isActive']]: isActive
  })} onClick={onChange} aria-pressed={isActive} aria-label={ariaLabel} data-sentry-component="InputToggle" data-sentry-source-file="index.tsx">
      <div className={styles.knob} />
      <div className={styles.label} aria-hidden={hasLabels}>
        {label}
      </div>
    </button>;
};