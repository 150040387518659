import { AnimatePresence, motion } from 'framer-motion';
import styles from './input-tooltip.module.scss';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
const modalVariants = {
  initial: () => ({
    opacity: 0,
    y: -10
  }),
  enter: () => ({
    opacity: 1,
    y: 0
  }),
  exit: {
    opacity: 0,
    y: -10
  }
};
export const InputTooltip = ({
  message,
  isActive,
  onClose
}) => {
  const {
    formatMessage
  } = useFormat({
    name: 'common'
  });
  return <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-component="InputTooltip" data-sentry-source-file="index.tsx">
      {isActive && <motion.div variants={modalVariants} initial={'initial'} animate={'enter'} transition={{
      type: 'spring',
      bounce: 0
    }} exit={'exit'} onClick={onClose} className={styles.tooltip}>
          <div className={styles.scrollContainer}>
            <div>{message}</div>
          </div>
          <div className={styles.closeButton}>
            <div aria-label={formatMessage({
          id: 'ariaLabel.closeBtn'
        })} className={styles.closeIcon}></div>
          </div>
        </motion.div>}
    </AnimatePresence>;
};