import classNames from 'classnames';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import styles from './input-radio-group.module.scss';
interface IInputRadioOption {
  label: string;
  id: string;
}
interface IInputRadio {
  onChange: (selectedOptionString: string) => void;
  options: IInputRadioOption[];
  activeId?: string;
  errorMessage?: string;
  errorClassName?: string;
  size?: 'regular' | 'small';
}
export const InputRadioGroup: React.FC<IInputRadio> = ({
  onChange,
  options,
  activeId,
  errorMessage,
  errorClassName,
  size
}) => {
  const {
    formatMessage
  } = useFormat({
    name: 'common'
  });
  return <div data-sentry-component="InputRadioGroup" data-sentry-source-file="index.tsx">
      <fieldset className={styles.radioGroup}>
        <legend className="sr-only">
          {formatMessage({
          id: 'chooseOption',
          defaultMessage: 'Option auswählen'
        })}
        </legend>

        <div className={styles.row}>
          {options?.map(option => <label key={`radio-option-${option.id}`} className={classNames(styles.radioButton, {
          [styles[`radioButton--isActive`]]: activeId === option.id,
          [styles[`size--${size}`]]: !!size
        })}>
              <input type="radio" name="options" value={option.id} checked={activeId === option.id} onChange={() => onChange(option.id)} className="sr-only" />
              {option.label}
            </label>)}
        </div>
        {errorMessage && <div className={classNames(errorClassName, styles.errorMessage)} role="alert">
            {errorMessage}
          </div>}
      </fieldset>
    </div>;
};