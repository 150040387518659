import { InputToggle, Typography } from '@engbers/components';
import { MediaType } from '@frontastic-engbers/types/engbers-custom';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import styles from './newsletter-signup-items-item.module.scss';
type NewsletterSignupItemsItemProps = {
  description: string;
  image?: MediaType;
  isActive?: boolean;
  onChange: () => void;
  labels: any;
};
export const NewsletterSignupItemsItem = ({
  description,
  image,
  isActive,
  labels,
  onChange
}: NewsletterSignupItemsItemProps) => {
  const {
    getTitle
  } = useImageSEO();
  const {
    formatMessage
  } = useFormat({
    name: 'newsletter'
  });
  return <div data-sentry-component="NewsletterSignupItemsItem" data-sentry-source-file="index.tsx">
      <div>
        <img className={styles.logo} src={image.media.file} alt={getTitle(image)} title={getTitle(image)} />
      </div>
      <div className={styles.description}>
        <Typography tag="div" size="l" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
          {description}
        </Typography>
      </div>
      <div className={styles.subscriptionRow}>
        <div>
          <Typography weight="bold" size="xxl" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
            {labels.subscribeRadioLabel}
          </Typography>
        </div>
        <div>
          <InputToggle isActive={isActive} onChange={onChange} activateAriaLabel={formatMessage({
          id: 'activateLabel',
          defaultMessage: 'Newsletter abonnieren'
        })} deactivateAriaLabel={formatMessage({
          id: 'deactivateLabel',
          defaultMessage: 'Newsletter abbestellen'
        })} data-sentry-element="InputToggle" data-sentry-source-file="index.tsx" />
        </div>
      </div>
    </div>;
};