import { MediaType } from '@frontastic-engbers/types/engbers-custom';
import useI18n from './useI18n';

export const useImageSEO = () => {
  const { t } = useI18n();

  const getTitle = (image: MediaType): string | undefined =>
    image.title && t(image.title) ? t(image.title) : image.media?.alt || image.media?.name;

  return {
    getTitle,
  };
};
