import { useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import { FormattedAmount, IconCustom } from '@engbers/components';
import styles from './order-item-accordion.module.scss';
type OrderItemAccordionProps = React.PropsWithChildren<{
  headline: string;
  subHeadline: string;
  isInitiallyOpen?: boolean;
  hasShiftedContent?: boolean;
  invoiceAmount?: number;
  colors?: {
    [key: string]: string;
  };
  id?: string;
}>;
export const OrderItemAccordion = ({
  children,
  headline,
  subHeadline,
  isInitiallyOpen,
  hasShiftedContent,
  invoiceAmount,
  colors,
  id
}: OrderItemAccordionProps) => {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen || false);
  const toggleOpen = useCallback(() => {
    setIsOpen(prevState => !prevState);
  }, []);
  const [isMinWidthMedium, setIsMinWidthMedium] = useState<boolean>(false);
  const isMobile = useMediaQuery({
    maxWidth: 1023
  });
  const contentId = `accordion-content-${id}`;
  const buttonId = `accordion-button-${id}`;
  const headlineId = `accordion-headline-${id}`;
  const subHeadlineId = `accordion-subheadline-${id}`;
  useEffect(() => {
    if (isMobile !== isMinWidthMedium) {
      setIsMinWidthMedium(isMobile);
    }
  }, [isMobile]);
  return <div className={classNames(styles.wrap, {
    [styles.wrapIsOpen]: isOpen
  })} data-sentry-component="OrderItemAccordion" data-sentry-source-file="index.tsx">
      <button id={buttonId} aria-expanded={isOpen} aria-controls={contentId} aria-labelledby={`${headlineId} ${subHeadlineId}`} onClick={toggleOpen} className={styles.header} style={{
      backgroundColor: colors ? colors.accordeonColor : undefined,
      color: colors ? colors.accordeonTextColor : undefined
    }}>
        <div>
          <div id={headlineId} className={styles.headline}>
            {headline}
          </div>
          <div id={subHeadlineId} className={styles.subHeadline}>
            {subHeadline}
          </div>
        </div>
        {!isMinWidthMedium && invoiceAmount > 0 && <div className={styles.amount}>
            <FormattedAmount amount={invoiceAmount} />
          </div>}
        <div className={classNames(styles.openButton)}>
          <IconCustom className={styles.chevronIcon} width={isMinWidthMedium ? 16 : 32} color="primary" icon="ChevronRightIcon" data-sentry-element="IconCustom" data-sentry-source-file="index.tsx" />
        </div>
      </button>

      {isOpen && <section id={contentId} aria-labelledby={buttonId} className={classNames(styles.content, {
      [styles.hasShiftedContent]: hasShiftedContent
    })} style={{
      backgroundColor: colors ? colors.accordeonContentColor : undefined,
      color: colors ? colors.accordeonContentTextColor : undefined
    }}>
          {children}
        </section>}
    </div>;
};