import React, { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useSWRConfig } from 'swr';
import classnames from 'classnames';
import { useAccount } from '@frontastic-engbers/lib';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { isPwaUser } from '@frontastic-engbers/helpers/utils/accountAuth';
import { IFTLinkReference, IFTPageFolderReference } from '@frontastic-engbers/types/engbers-custom';
import { Markdown } from '@engbers/components';
import { AccountNavPoint } from './navigation-point';
import styles from './my-account.module.scss';
interface MyAccountType {
  title: string;
  textSize?: number;
  navPoints: NavPoint[];
  isEmilio?: boolean;
}
interface NavPoint {
  navPointLabel: string;
  navPointLink: IFTPageFolderReference | IFTLinkReference;
  isLogout: boolean;
  subnavPoints: SubnavPoint[];
  pwaUserOnly: boolean;
}
interface SubnavPoint {
  subnavPointLabel: string;
  subnavPointLink: IFTPageFolderReference | IFTLinkReference;
}
export const MyAccount: React.FC<MyAccountType> = data => {
  const {
    mutate
  } = useSWRConfig();
  const {
    logout,
    loggedIn,
    account
  } = useAccount();
  const router = useRouter();
  const {
    formatMessage
  } = useFormat({
    name: 'common'
  });
  const handleLogout = useCallback(async () => {
    await mutate(() => true, undefined, {
      revalidate: true
    });
    await logout();
    return router.push('/login');
  }, [logout, mutate, router]);
  const isNavPointActive = (link: IFTPageFolderReference | IFTLinkReference) => link && 'pageFolder' in link && link.pageFolder._url === router.asPath;
  const mappedNavPoints = useMemo(() => data.navPoints.filter(point => !(point.isLogout && !loggedIn || point.pwaUserOnly && !isPwaUser(account))).map((navPoint, index) => <AccountNavPoint key={`nav-point-key-${index}`} navPointLabel={navPoint.navPointLabel} navPointLink={navPoint.navPointLink} isLogout={navPoint.isLogout} subnavPoints={navPoint.subnavPoints} isNavPointActive={isNavPointActive} handleLogout={handleLogout} />), [data.navPoints, loggedIn, account]);
  return <nav aria-label={formatMessage({
    id: 'accountNavigationLabel',
    defaultMessage: 'Account'
  })} className={styles.myAccount} data-sentry-component="MyAccount" data-sentry-source-file="index.tsx">
      <Markdown className={styles.heading} text={data.title} textSize={data.textSize} data-sentry-element="Markdown" data-sentry-source-file="index.tsx" />
      <ul className={classnames(styles.accountNavPoints, {
      [styles.emilioAccountNavPoints]: data.isEmilio
    })}>
        {mappedNavPoints}
      </ul>
    </nav>;
};