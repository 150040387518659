import { Image, Markdown } from '@engbers/components';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import styles from './navigation-side-header.module.scss';
interface INavigationSideHeader {
  headline: string;
  textSize?: number;
  headlineTextColor: string;
  headlineBgColor: string;
  headlinePosition: 'flex-start' | 'center' | 'flex-end';
  icon: {
    width: number;
    url: string;
  };
  topImage: any;
}
export const NavigationSideHeader: React.FC<INavigationSideHeader> = ({
  headline,
  textSize,
  headlineTextColor,
  headlineBgColor,
  headlinePosition,
  icon,
  topImage
}) => {
  const {
    getTitle
  } = useImageSEO();
  return <div className={styles.wrap} style={{
    backgroundColor: headlineBgColor?.length ? headlineBgColor : undefined
  }} data-sentry-component="NavigationSideHeader" data-sentry-source-file="index.tsx">
      <div className={styles.topImage}>
        {topImage?.media && <Image media={topImage.media} alt={getTitle(topImage)} title={getTitle(topImage)} />}
      </div>
      <div className={styles.headlineWrap} style={{
      justifyContent: headlinePosition
    }}>
        <img src={icon.url} width={icon.width || 24} alt="icon" title="icon" />
        <h1 className={styles.headline} style={{
        color: headlineTextColor?.length ? headlineTextColor : undefined
      }}>
          <Markdown text={headline} textSize={textSize} disableMargin data-sentry-element="Markdown" data-sentry-source-file="index.tsx" />
        </h1>
      </div>
    </div>;
};