import { CSSProperties, useMemo } from 'react';
import classnames from 'classnames';
import { IconCustom, Image, LoadingIndicatorInline, Markdown } from '@engbers/components';
import { IFTLinkReference, IFTPageFolderReference } from '@frontastic-engbers/types/engbers-custom';
import { ReferenceLink } from '@frontastic-engbers/helpers/reference';
import styles from './button.module.scss';
export interface IButton {
  label: string;
  textSize?: number;
  onClick?: (e: any) => void;
  isLoading?: boolean;
  hasIcon?: boolean;
  customIconURL?: string;
  customIconWidth?: string;
  iconColor?: 'white' | 'green' | 'primary' | 'green-neon' | 'custom' | undefined;
  type?: 'cta' | 'primary' | 'secondary' | 'link';
  size?: 'default' | 'large' | 'small';
  buttonType?: 'button' | 'submit' | 'reset';
  color?: string;
  textColor?: string;
  link?: IFTLinkReference | IFTPageFolderReference;
  customStyle?: object;
  className?: string;
  disabled?: boolean;
  href?: string;
  target?: string;
}
export const Button: React.FC<IButton> = ({
  label,
  textSize = 0,
  onClick,
  isLoading,
  hasIcon = true,
  customIconURL,
  iconColor = 'white',
  type = 'cta',
  customIconWidth,
  size,
  buttonType = 'button',
  link,
  color,
  textColor,
  customStyle,
  className,
  disabled = false,
  href,
  target = '_self'
}) => {
  const generateIcon = useMemo(() => {
    if (isLoading) {
      return <LoadingIndicatorInline />;
    }
    if (hasIcon) {
      if (customIconURL) {
        return <Image width={`${customIconWidth}px`} src={customIconURL} alt={label} title={label} />;
      } else {
        return <IconCustom width={12} color={iconColor} icon="ChevronRightIcon" />;
      }
    }
  }, [isLoading, hasIcon, customIconURL, customIconWidth, label]);
  const generateHtmlTag = (classNames: string, styles: CSSProperties) => {
    if (link) {
      // Todo: a11y (refactor Button and ReferenceLink)
      return <div className={classNames} onClick={onClick} style={styles}>
          <Markdown text={label} textSize={textSize} disableMargin />
          {hasIcon && <div>{generateIcon}</div>}
        </div>;
    }
    return <button type={buttonType} className={classNames} onClick={onClick} style={styles} disabled={disabled} data-sentry-component="generateHtmlTag" data-sentry-source-file="index.tsx">
        <Markdown text={label} textSize={textSize} disableMargin data-sentry-element="Markdown" data-sentry-source-file="index.tsx" />
        {hasIcon && <div>{generateIcon}</div>}
      </button>;
  };
  const generateLinkContent = () => {
    return generateHtmlTag(classnames(styles.button, {
      [styles[`button--is-loading`]]: isLoading,
      [styles[`button--type-${type}`]]: !!type,
      [styles[`button--size-${size}`]]: !!size
    }, className), {
      ...customStyle,
      color: textColor,
      backgroundColor: color && type === 'cta' ? color : undefined,
      borderColor: color && (type === 'cta' || type === 'primary') ? color : undefined
    });
  };
  if (link) {
    return <ReferenceLink target={link}>{generateLinkContent()}</ReferenceLink>;
  }
  if (href) {
    return <a href={href} target={target}>
        {generateLinkContent()}
      </a>;
  }
  return generateLinkContent();
};