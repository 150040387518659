import React from 'react';
import classnames from 'classnames';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import { ReferenceLink } from '@frontastic-engbers/helpers/reference';
import { IFTLinkReference, IFTPageFolderReference, MediaType } from '@frontastic-engbers/types/engbers-custom';
import { CustomMarkdown } from '@engbers/components';
import styles from './header-minimized.module.scss';
export interface HeaderMinimizedType {
  mobileCheckoutLogo: MediaType;
  mobileCheckoutLogoLink: IFTLinkReference | IFTPageFolderReference;
  trustedShopIcon: MediaType;
  trustedShopText: string;
  isCheckout: boolean;
  isEnGermany: boolean;
  isEmilio?: boolean;
}
export const HeaderMinimized: React.FC<HeaderMinimizedType> = ({
  mobileCheckoutLogo,
  mobileCheckoutLogoLink,
  trustedShopIcon,
  trustedShopText,
  isCheckout,
  isEnGermany,
  isEmilio = false
}) => {
  const {
    getTitle
  } = useImageSEO();
  return <header className={classnames(styles.HeaderMinimizedWrap, {
    [styles.emilioHeaderMinimizedWrap]: isEmilio,
    [styles.isCheckout]: isCheckout
  })} style={isEnGermany && !isEmilio ? {
    backgroundColor: '#342e2b'
  } : undefined} data-sentry-component="HeaderMinimized" data-sentry-source-file="index.tsx">
      <div className={classnames(styles.HeaderMinimized, {
      [styles.emilioHeaderMinimized]: isEmilio,
      [styles.emilioCheckoutHeaderMinimized]: isEmilio && isCheckout
    })} style={isEnGermany && !isEmilio ? {
      backgroundColor: '#342e2b'
    } : undefined}>
        <div className={styles.logoWrapper}>
          <ReferenceLink target={mobileCheckoutLogoLink} data-sentry-element="ReferenceLink" data-sentry-source-file="index.tsx">
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img src={mobileCheckoutLogo.media.file} alt={getTitle(mobileCheckoutLogo)} title={getTitle(mobileCheckoutLogo)} style={!isCheckout ? {
            padding: 0
          } : undefined} />
          </ReferenceLink>
        </div>
        {isCheckout && <div className={styles.tsWrapper}>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img src={trustedShopIcon.media.file} alt={getTitle(trustedShopIcon)} title={getTitle(trustedShopIcon)} />
            <div className={styles.tsText}>
              <CustomMarkdown text={trustedShopText} />
            </div>
          </div>}
      </div>
    </header>;
};