import React from 'react';
import { IFTLinkReference, IFTPageFolderReference, MediaType } from '@frontastic-engbers/types/engbers-custom';
import { ReferenceLink } from '@frontastic-engbers/helpers/reference';
import { IconCustom, Image } from '@engbers/components';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import styles from './category-image-text.module.scss';
interface CategoryImageTextType {
  categoriesImageText: CategoryBoxType[];
  isEmilio?: boolean;
}
interface CategoryBoxType {
  image: MediaType;
  link: IFTLinkReference | IFTPageFolderReference;
  button: string;
  buttonType: string;
}
export const CategoryImageText: React.FC<CategoryImageTextType> = ({
  categoriesImageText,
  isEmilio
}) => {
  const {
    getTitle
  } = useImageSEO();
  return <div className={styles.wrap} data-sentry-component="CategoryImageText" data-sentry-source-file="index.tsx">
      {categoriesImageText.map((categoryBox, index) => {
      if (!categoryBox.image?.media) {
        return null;
      }
      return <div key={`categoryBox-${index}`} className={styles.categoryImageTextBox}>
            <div className={styles.imgFullHeight}>
              <ReferenceLink target={categoryBox.link}>
                <Image src={categoryBox?.image?.media?.file} alt={getTitle(categoryBox.image)} title={getTitle(categoryBox.image)} />
              </ReferenceLink>
            </div>
            <div className={styles.buttonWrapper}>
              <ReferenceLink target={categoryBox.link} className={`btn ${!isEmilio ? categoryBox?.buttonType : styles.eaButton} ${styles.button}`}>
                {categoryBox?.button}
                <IconCustom width={16} className={styles.iconWidth} color={categoryBox?.buttonType === 'cta' ? isEmilio ? 'custom' : 'white' : 'primary'} customColor={isEmilio && '#000000'} icon={'ChevronRightIcon'} />
              </ReferenceLink>
            </div>
          </div>;
    })}
    </div>;
};