import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { fetchApiHub } from '@frontastic-engbers/lib';
import { Button, IconCustom, InputCheckbox } from '@engbers/components';
import LocationResult from '@engbers/shop-backend/online-shop/models/LocationResult';
import Spinner from '../../../online-shops/commercetools-ui/spinner';
import styles from './store-finder.module.scss';
export const StoreFinder = ({
  selectedStoreId,
  currentZipCode,
  setSelectedStoreId,
  labels
}) => {
  const {
    formatMessage
  } = useFormat({
    name: 'common'
  });
  const [foundStores, setFoundStores] = useState<LocationResult[]>([]);
  const [isInitial, setIsInitial] = useState(true);
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [isLoadingResult, setIsLoadingResult] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSearchTerm, setSelectedSearchTerm] = useState('');
  const [checkedStoreId, setCheckedStoreId] = useState('');
  const [hasOpenStorefinder, setHasOpenStorefinder] = useState(false);
  const [selectedStoreDetails, setSelectedStoreDetails] = useState<any | undefined>(undefined);
  const chooseZip = useCallback(async () => {
    const foundZips = await search(currentZipCode);
    if (foundZips.length) {
      setSelectedStoreId(foundZips[0].id);
      setSelectedStoreDetails(foundZips[0]);
    }
  }, [currentZipCode]);
  const getInitialState = async () => {
    setIsLoadingResult(true);
    if (String(currentZipCode)?.length === 5) {
      await chooseZip();
    } else {
      setSelectedStoreId('');
    }
    setIsLoadingResult(false);
    setIsInitial(false);
  };
  const reactToZipChanges = useCallback(async () => {
    if (!selectedStoreId) {
      setIsLoadingResult(true);
      await chooseZip();
      setIsLoadingResult(false);
    }
  }, [selectedStoreId, chooseZip]);

  // later zip changes reaction
  useEffect(() => {
    if (!isInitial && String(currentZipCode)?.length === 5) {
      reactToZipChanges();
    }
  }, [reactToZipChanges, currentZipCode, isInitial]);

  // Initial adjustment of storeid
  useEffect(() => {
    // if store id already given but don't have details, fetch details
    getInitialState();
  }, []);
  const toggleStorefinder = useCallback(() => {
    setHasOpenStorefinder(prev => !prev);
  }, []);
  const search = useCallback(async (searchTerm: string) => {
    const returnedStores: LocationResult[] = await fetchApiHub(`/action/stores/getStoresByLocation`, {
      method: 'POST'
    }, {
      search: searchTerm,
      type: 'searchTerm'
    });
    setFoundStores(returnedStores);
    return returnedStores;
  }, [selectedSearchTerm]);
  const onSearchInput = useCallback(event => {
    setCheckedStoreId('');
    setSearchTerm(event.target.value);
  }, [setSearchTerm]);
  const selectTemporarySelectStoreId = useCallback((storeId: string) => {
    setCheckedStoreId(storeId);
  }, []);
  const confirmTemporarySelectedStore = useCallback(() => {
    setSelectedStoreId(checkedStoreId);
    setSelectedStoreDetails(foundStores.find(store => store.id === checkedStoreId));
    setCheckedStoreId('');
    setSelectedSearchTerm('');
    setSearchTerm('');
    setHasOpenStorefinder(false);
  }, [checkedStoreId]);
  const currentStore = useMemo(() => {
    return selectedStoreDetails;
  }, [selectedStoreId, foundStores, selectedStoreDetails]);
  const onRemoveSearchTerm = useCallback(() => {
    setSearchTerm('');
    setSelectedSearchTerm('');
  }, []);
  const startSearch = useCallback(async () => {
    setIsLoadingList(true);
    setSelectedSearchTerm(searchTerm);
    await search(searchTerm);
    setIsLoadingList(false);
    return false;
  }, [searchTerm, search]);
  const onInputKeyDown = useCallback((event: any) => {
    if (event.key === 'Enter') {
      event.stopPropagation();
      event.preventDefault();
      startSearch();
    }
  }, [startSearch]);
  return <div className={styles.wrap} data-sentry-component="StoreFinder" data-sentry-source-file="index.tsx">
      {currentStore && !isLoadingResult && <div>
          <div className={styles.selectedItem}>
            <div>{currentStore.name}</div>
            <div>{`${currentStore.street} ${currentStore.streetNumber}`}</div>
            <div className={styles.selectedItemZip}>{`${currentStore.zip} ${currentStore.city}`}</div>
            <div>{`${formatMessage({
            id: 'done',
            defaultMessage: 'Fertig'
          })} ${currentStore.id}`}</div>
          </div>
        </div>}
      {isLoadingResult && <div className={styles.selectedItem}>
          <Spinner size="x-small" color="#0f202f" />
        </div>}
      {selectedStoreId && <button type="button" className={styles.changeButton} onClick={toggleStorefinder}>
          {labels.changeStore}
        </button>}
      {(hasOpenStorefinder || !selectedStoreId) && <div>
          <div className={styles.searchWrap}>
            <div className={styles.searchInputWrap}>
              <input name="search" onKeyDown={onInputKeyDown} value={searchTerm} placeholder="Stadt oder PLZ" className={styles.searchInput} onChange={onSearchInput} type="search" />
              {!!searchTerm && <button type="button" className={styles.removeButton} onClick={onRemoveSearchTerm} aria-label={formatMessage({
            id: 'storeSearchClearLabel',
            defaultMessage: 'Eingabe leeren'
          })}>
                  <IconCustom width={10} icon="Close" />
                </button>}
              <button type="button" className={styles.searchButtonWrap} onClick={startSearch} aria-label={formatMessage({
            id: 'storeSearchLabel',
            defaultMessage: 'Filialen suchen'
          })}>
                <IconCustom width={24} icon="Search" />
              </button>
            </div>
            {isLoadingList && <div className={styles.searchResultsWrap}>
                <div className={styles.searchResultItem}>
                  <Spinner size="x-small" color="#0f202f" />
                </div>
              </div>}
            {!!foundStores?.length && !isLoadingList && <div className={styles.searchResultsWrap}>
                {foundStores.map(store => {
            return <label key={store.id} className={styles.searchResultItem} aria-label={formatMessage({
              id: 'storeSelectionLabel',
              defaultMessage: '{storeName} Store, gelegen in der Straße {storeStreet} in {storeCity} auswählen',
              values: {
                storeName: store.name,
                storeStreet: store.street,
                storeCity: store.city
              }
            })}>
                      <div className={styles.searchResultItemCheckboxWrap}>
                        <InputCheckbox id={`store-${store.id}`} style="white" checked={store.id === checkedStoreId} onChange={() => selectTemporarySelectStoreId(store.id)} />
                      </div>
                      <span>{store.name}</span>
                      <span>{`${store.street} ${store.streetNumber}`}</span>
                      <span className={styles.searchResultItemCity}>{`${store.zip} ${store.city}`}</span>
                      <span>{`${formatMessage({
                  id: 'done',
                  defaultMessage: 'Fertig'
                })} ${store.id}`}</span>
                    </label>;
          })}
              </div>}
          </div>
          {!!foundStores?.length && !isLoadingList && <div className={styles.doneButtonWrap}>
              <Button onClick={confirmTemporarySelectedStore} disabled={!checkedStoreId} hasIcon={false} label={formatMessage({
          id: 'done',
          defaultMessage: 'Fertig'
        })} />
            </div>}
        </div>}
    </div>;
};