import React, { useEffect, useState } from 'react';
import Sticky from 'react-sticky-el';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import { ReferenceLink } from '@frontastic-engbers/helpers/reference';
import { useWishlist } from '@frontastic-engbers/lib';
import { IconCustom, Image, Markdown } from '@engbers/components';
import { IFTLinkReference, MediaType } from '@frontastic-engbers/types/engbers-custom';
import styles from './header-second-level.module.scss';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
interface IHeaderSecondLevel {
  title: string;
  textSize?: number;
  bgColor?: string;
  textColor?: string;
  icon?: MediaType;
  link: IFTLinkReference;
  isWishlist?: boolean;
}
export const HeaderSecondLevel: React.FC<IHeaderSecondLevel> = ({
  title,
  textSize,
  bgColor,
  textColor,
  icon,
  link,
  isWishlist
}) => {
  const {
    getTitle
  } = useImageSEO();
  const {
    data
  } = useWishlist();
  const [showAmountBubble, setShowAmountBubble] = useState(false);
  const {
    formatMessage
  } = useFormat({
    name: 'common'
  });
  useEffect(() => {
    if (data && data.lineItems?.length >= 1) {
      setShowAmountBubble(true);
    }
  }, [data?.lineItems?.length]);
  return <Sticky stickyClassName={styles.sticky} data-sentry-element="Sticky" data-sentry-component="HeaderSecondLevel" data-sentry-source-file="index.tsx">
      <div className={styles.wrap} style={{
      backgroundColor: bgColor,
      color: textColor
    }}>
        <div className={styles.content}>
          <ReferenceLink target={link} title={formatMessage({
          id: 'navigation.back',
          defaultMessage: 'Zurück'
        })} data-sentry-element="ReferenceLink" data-sentry-source-file="index.tsx">
            <IconCustom width={20} color="custom" icon="ArrowThinLeft" data-sentry-element="IconCustom" data-sentry-source-file="index.tsx" />
          </ReferenceLink>
          <div className={styles.title} style={{
          color: textColor,
          borderLeftColor: textColor
        }}>
            {icon?.media?.file && <Image src={icon.media.file} width={22} alt={getTitle(icon)} title={getTitle(icon)} />}
            <Markdown text={title} textSize={textSize} disableMargin data-sentry-element="Markdown" data-sentry-source-file="index.tsx" />
            {isWishlist && showAmountBubble && <div className={styles.amountBubble}>{data.lineItems.length}</div>}
          </div>
        </div>
      </div>
    </Sticky>;
};