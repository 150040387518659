import React from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import { useCart, useCurrentShop, useWishlist } from '@frontastic-engbers/lib';
import { ReferenceLink } from '@frontastic-engbers/helpers/reference';
import { IFTLinkReference, IFTPageFolderReference, MediaType } from '@frontastic-engbers/types/engbers-custom';
import { ServiceType } from '../header-service-area';
import LoadingPlaceholder from '@frontastic-engbers/lib/lib/loading-placeholder';
import styles from './header-main.module.scss';
export interface HeaderMainType {
  logos: Logo[];
  items: Item[];
  isEmilio?: boolean;
  services?: ServiceType[];
}
interface Logo {
  logo: MediaType;
  logoLink: IFTLinkReference | IFTPageFolderReference;
  relativePath: string;
  activeShop: 'engbers' | 'engbers-xxl' | 'engbers-germany' | 'emilio-adani';
}
export interface Item {
  tabType: 'isMyAccount' | 'isWishlist' | 'isBasket';
  showLogoutBtnInHeader: boolean;
  itemIcon: MediaType;
  itemLabel: string;
  itemLink: IFTLinkReference | IFTPageFolderReference;
}
export interface Amount {
  amount: number | null;
}
export const HeaderMain: React.FC<HeaderMainType> = ({
  logos,
  items,
  isEmilio = false,
  services
}) => {
  const {
    getTitle
  } = useImageSEO();
  const {
    data: cart
  } = useCart();
  const {
    data: wishlist
  } = useWishlist();
  const {
    asPath
  } = useRouter();
  const currentSection = useCurrentShop();
  const getLineItemsCount = (): number => cart.lineItems.reduce((accumulator, lineItem) => accumulator + lineItem.count, 0);
  const HeaderServiceArea = dynamic(() => import('@engbers/components/header-service-area').then(module => module.HeaderServiceArea), {
    loading: () => <LoadingPlaceholder height="50px" />
  });
  const HeaderItem = dynamic(() => import('./header-item').then(module => module.HeaderItem), {
    loading: () => <LoadingPlaceholder height="50px" />
  });
  const getHeaderItemAmount = (itemType: string): number | null => {
    switch (itemType) {
      case 'isBasket':
        return cart?.lineItems ? getLineItemsCount() : null;
      case 'isWishlist':
        return wishlist?.lineItems?.length || null;
      default:
        return null;
    }
  };
  return <header className={classnames(styles.HeaderMainWrap, {
    [styles.emilioHeaderMainWrap]: isEmilio,
    [styles.enGermanyHeaderMainWrap]: currentSection === 'engbers-germany'
  })} data-sentry-component="HeaderMain" data-sentry-source-file="index.tsx">
      <div className={classnames(styles.HeaderMain, {
      [styles.emilioHeaderMain]: isEmilio,
      [styles.enGermanyHeaderMain]: currentSection === 'engbers-germany'
    })}>
        {isEmilio && <div className={styles.emilioHeaderServiceWrap}>
            <HeaderServiceArea services={services} isFullWidth={false} isEmilio={isEmilio} />
          </div>}
        <div className={styles.logosWrapper}>
          {logos.map((logo, index) => {
          const isEmilioAdaniNotSelected = logo.activeShop === 'emilio-adani' && !asPath.includes(logo.relativePath);
          if (logo.logo.media.format !== 'svg') {
            return null;
          }
          return <ReferenceLink key={`logo-link-${index}`} target={logo.logoLink}>
                <img src={logo.logo.media.file} alt={getTitle(logo.logo)} title={getTitle(logo.logo)} className={classnames({
              [styles['isActive']]: currentSection === logo.activeShop,
              [styles['emilioAdaniNotSelected']]: isEmilioAdaniNotSelected
            })} style={logo.activeShop === 'emilio-adani' ? {
              marginBottom: '-14px'
            } : logo.activeShop === 'engbers' ? {
              marginBottom: '-8px'
            } : undefined} />
              </ReferenceLink>;
        })}
        </div>
        <div className={styles.itemsWrapper}>
          {items.map((item, index) => <HeaderItem key={`header-item-${index}`} tabType={item.tabType} showLogoutBtnInHeader={item.showLogoutBtnInHeader} itemIcon={item.itemIcon} itemLabel={item.itemLabel} itemLink={item.itemLink} amount={getHeaderItemAmount(item.tabType)} />)}
        </div>
      </div>
    </header>;
};