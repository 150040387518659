import { Block, Button } from '@engbers/components';
import { InputText } from '@engbers/components/shopmacher-ui/input-text';
import React, { FormEvent, useState } from 'react';
import { useAccount } from '@frontastic-engbers/lib';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { useToastNotificationsActions } from '@frontastic-engbers/lib/state/notification/actions';
type ForgotPasswordFormData = {
  email: string;
};
interface IForgotPasswordFormCompoment {
  infoText?: string;
  infoTextHeadline?: string;
}
export const ForgotPasswordForm: React.FC<IForgotPasswordFormCompoment> = ({
  infoText,
  infoTextHeadline
}) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<ForgotPasswordFormData>({
    email: ''
  });
  const {
    pushNotification
  } = useToastNotificationsActions();
  const {
    formatMessage: formatAccountMessage
  } = useFormat({
    name: 'account'
  });
  const {
    requestPasswordReset
  } = useAccount();
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    await requestPasswordReset(formData.email);
    pushNotification(formatAccountMessage({
      id: 'password.resetMsg',
      values: {
        email: formData.email
      }
    }), 'success');
    setLoading(false);
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  return <form onSubmit={handleSubmit} data-sentry-component="ForgotPasswordForm" data-sentry-source-file="index.tsx">
      <Block marginBottom={2} data-sentry-element="Block" data-sentry-source-file="index.tsx">
        <InputText id="email" name="email" type="email" onChange={handleChange} useInfoModal infoText={infoText || 'text'} infoTextHeadline={infoTextHeadline} placeholder={formatAccountMessage({
        id: 'your.email'
      })} value={formData.email} data-sentry-element="InputText" data-sentry-source-file="index.tsx" />
      </Block>
      <Block marginBottom={2} data-sentry-element="Block" data-sentry-source-file="index.tsx">
        <Button size="large" isLoading={loading} onClick={handleSubmit} label={formatAccountMessage({
        id: 'password.reset'
      })} data-sentry-element="Button" data-sentry-source-file="index.tsx" />
      </Block>
    </form>;
};