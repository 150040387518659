import classnames from 'classnames';
import { ReferenceLink } from '@frontastic-engbers/helpers/reference';
import styles from './link.module.scss';
export interface ILink {
  href?: string;
  reference?: any;
  openInNewWindow?: boolean;
  className?: string;
  title?: string;
  onClick?: () => void;
  ariaLabel?: string;
}
export const Link: React.FC<ILink> = ({
  children,
  href,
  reference,
  openInNewWindow,
  className,
  title,
  onClick,
  ariaLabel
}) => {
  return <ReferenceLink onClick={onClick} title={title || href} className={classnames(styles.link, className)} target={reference || {
    link: href,
    type: 'link',
    openInNewWindow: openInNewWindow
  }} ariaLabel={ariaLabel} data-sentry-element="ReferenceLink" data-sentry-component="Link" data-sentry-source-file="index.tsx">
      {children}
    </ReferenceLink>;
};