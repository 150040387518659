import React, { ReactNode, useCallback, useState } from 'react';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { v4 as uuid } from 'uuid';
import { MediaType } from '@frontastic-engbers/types/engbers-custom';
import { IconCustom, Image, Markdown } from '@engbers/components';
import { getProjectInfo } from '@frontastic-engbers/lib';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import styles from './accordion-item.module.scss';
const AnimatePresence = dynamic(() => import('framer-motion').then(module => module.AnimatePresence));
const CustomMotion = dynamic(() => import('@frontastic-engbers/lib/lib/custom-motion'));
export interface IAccordionItem {
  label: string;
  textSize?: number;
  icon?: MediaType;
  iconWidth?: number;
  headerPadding?: number;
  headerFontSize?: number;
  borderColor?: string;
  children: ReactNode;
  isInitiallyOpen?: boolean;
}
const openVariants = {
  initial: {
    height: 0,
    opacity: 0
  },
  animate: {
    height: 'auto',
    opacity: 1
  },
  exit: {
    height: 0,
    opacity: 0
  }
};
export const AccordionItem: React.FC<IAccordionItem> = ({
  label,
  textSize,
  icon,
  iconWidth = null,
  headerPadding,
  headerFontSize,
  borderColor,
  children,
  isInitiallyOpen
}) => {
  const isPwa = getProjectInfo()['projectId'] === 'Pwa';
  const {
    getTitle
  } = useImageSEO();
  const [uniqueId] = useState(uuid);
  const [isOpen, setIsOpen] = useState(isInitiallyOpen || false);
  const toggleOpen = useCallback(() => {
    setIsOpen(prevState => !prevState);
  }, []);
  const onClickContent = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    return false;
  }, []);
  const contentId = `accordion-content-${uniqueId}`;
  const buttonId = `accordion-button-${uniqueId}`;
  return <div className={classNames(styles.wrap, {
    [styles['isEN']]: !isPwa
  })} style={{
    marginBottom: headerPadding,
    borderColor: borderColor
  }} data-sentry-component="AccordionItem" data-sentry-source-file="index.tsx">
      <button className={classNames(styles.header, {
      [styles['isEN']]: !isPwa
    })} onClick={toggleOpen} style={{
      padding: headerPadding,
      paddingBottom: headerPadding ? 0 : undefined,
      fontSize: headerFontSize ? `${headerFontSize}px` : undefined
    }} aria-expanded={isOpen} aria-controls={contentId} id={buttonId}>
        <div className={styles.headerIconWrap}>
          {icon?.media && <Image src={icon.media.file} alt={getTitle(icon)} title={getTitle(icon)} width={iconWidth || 30} />}
          <Markdown text={label} textSize={textSize} disableMargin data-sentry-element="Markdown" data-sentry-source-file="index.tsx" />
        </div>
        <div className={classNames(styles.chevronIconWrap, {
        [styles[`chevronIconWrap--is-active`]]: isOpen
      })}>
          <IconCustom width={14} icon="ChevronRightIcon" data-sentry-element="IconCustom" data-sentry-source-file="index.tsx" />
        </div>
      </button>
      <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-source-file="index.tsx">
        <CustomMotion openVariants={openVariants} onClick={onClickContent} classNames={classNames(styles.content, {
        [styles[`isActive`]]: isOpen,
        [styles[`isEN`]]: !isPwa
      })} data-sentry-element="CustomMotion" data-sentry-source-file="index.tsx">
          <section id={contentId} aria-labelledby={buttonId}>
            {children}
          </section>
        </CustomMotion>
      </AnimatePresence>
    </div>;
};